<template>
  <div id="chilldoge">
    <main id="main-content">
      <router-view></router-view>
    </main>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Alumni+Sans:ital,wght@0,100..900;1,100..900&family=Comic+Neue:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&family=Iceberg&family=Protest+Revolution&display=swap');

#app {
  font-family: "Iceberg", sans-serif;
  font-weight: bold;
  text-align: center;
  position: relative;
  overflow: hidden;
}

body {
  margin: 0px;
}

</style>
